import sha256 from 'crypto-js/sha256'
import {PASSCODE} from "../config";
import {fromJS} from "immutable";

export function authenticate(code) {
  let ms = 2000
  if (process.env.NODE_ENV !== 'production') {
    ms = 100;
  }
  return new Promise((resolve, reject) => {
    let values = fromJS(PASSCODE.split(","));
    setTimeout(() => {
      if (values.some((val) => sha256(code).toString() === val)) {
        if (hasExpired(code, new Date())) {
          resolve({success: false, error: false})
        } else {
          resolve({success: true, error: false})
        }
        resolve({success: true, error: false})
      } else {
        resolve({success: false, error: false})
      }
    }, ms)
  });
}

export function hasExpired(code, date) {
  if (code.length <= 7) return false;
  let char = parseInt(code[7], 10);
  let curM = date.getMonth();
  let curY = date.getFullYear()
  if (isNaN(char)) return false;
  if (curY > 2022) return true;
  let num = char - 1;
  if (num < 0 && curY === 2021) return false;
  if (num < 0 && curY > 2021) return true;
  if (num < curM && curY === 2022) return true;
  return false;
}