import React, {useState} from "react";
import {Map, List} from "immutable";
import {positionStyles, marginStyles, paddingStyles, applyStyles, groupStyles, applyAbsoluteMeasurements} from "../../ModuleStyles";
import CircularControls from "../../components/circular_controls/CircularControls";
import ProgressDots from "../../components/progress_dots/ProgressDots";
import Slide from "./Slide";
import FadeSlide from "./FadeSlide";
import Dimensions from "../../../lib/Dimensions";
import {createModuleFromId} from "../../ModuleFactory";

export default function SlideContainer(props) {
  let {width, height, data, modules, moduleId} = props;
  let [currentIndex, setCurrentIndex] = useState(0);
  // let [autoAdvance, setAutoAdvance] = useState(false);
  // let autoAdvanceSecs = data.get("autoAdvance", "0s");

  // useEffect(() => {
  //   let timerMS = secondsToMilliSeconds(autoAdvanceSecs);
  //   if (timerMS > 0) {
  //     console.log("startAutoAdvance");
  //     this.startAutoAdvance(timerMS);
  //   }
  // }, [autoAdvanceSecs])

  let moduleOrder = data.get("moduleOrder", List());
  let styles = getStyles(data, width, height);

  if (styles.has("@preserveRatio")) {
    let dims = Dimensions.fromString(styles.get("@preserveRatio"));
    let newHeight = Math.round(dims.transformW(width).h);
    if (!isNaN(newHeight)) {
      styles = styles.set("height", newHeight).remove("@preserveRatio");
    }
  }

  let x = width * currentIndex;
  let renderWithFade = data.get("style", "move") === "fade";
  let innerStyles = {
    position: "absolute",
    left: "0px",
    top: "0px",
    width: `${width}px`,
    height: `${height}px`
  }
  if (!renderWithFade) {
    innerStyles.transform = `translateX(-${x}px)`;
    innerStyles.transition = getTransition(data);
  }

  let outerControlsStyles = getOuterControlsStyles(data);
  
  let elements = null;
  if (renderWithFade) {
    let fadeTransition = getFadeTransition(data);
    elements = moduleOrder.map((moduleId, index) => {
      return <FadeSlide key={moduleId} transition={fadeTransition} active={index === currentIndex} width={width} height={height} left={0}>{createModuleFromId(moduleId, modules, width, height)}</FadeSlide>
    });
  } else {
    elements = moduleOrder.map((moduleId, index) => {
      return <Slide key={moduleId} currentIndex={currentIndex} width={width} height={height} left={width * index}>{createModuleFromId(moduleId, modules, width, height)}</Slide>
    });
  }

  function handleNext() {
    // this.stopAutoAdvance();
    if ((currentIndex + 1) < moduleOrder.size) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  }

  function handlePrev() {
    // this.stopAutoAdvance();
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex-1);
    } else {
      setCurrentIndex(moduleOrder.size-1);
    }
  }

  function handleChange(index) {
    // this.stopAutoAdvance();
    setCurrentIndex(index);
  }  

  let controls = null;
  var controlsStyles = getControlsStyles(data);
  if (data.get("controls", "") === "dots") {
    controls = <ProgressDots
                total={moduleOrder.size}
                index={currentIndex}
                onChange={handleChange}
                />
  } else {
    controls = <CircularControls
                 styles={controlsStyles} 
                 circular={true}
                 total={moduleOrder.size}
                 current={currentIndex}
                 nextSlide={handleNext}
                 prevSlide={handlePrev}
                 />  
  }

  return (
    <div className="SlideContainer" style={styles.toJS()} id={moduleId}>
      <div className="inner" style={innerStyles}>
        {elements}
      </div>
      <div className="controls" style={outerControlsStyles.toJS()}>
        {controls}
      </div>
    </div>
  )
}

function getTransition(data) {
  let transition = data.get("transition", Map({duration: "1s", easing: "cubic-bezier(0.23, 1, 0.32, 1)"}));
  return `transform ${transition.get("duration")} ${transition.get("easing")}`;
}

function getFadeTransition(data) {
  let transition = data.get("transition", Map({duration: "1s", easing: "cubic-bezier(0.23, 1, 0.32, 1)"}));
  return `opacity ${transition.get("duration")} ${transition.get("easing")}`;
}

function getOuterControlsStyles(data) {
  let defaultStyles = Map({position: "absolute", left: "25px", bottom: "25px", zIndex: 99});
  let styles = data.getIn(["styles", "#controls"], Map());
  return applyStyles(
    groupStyles(
      marginStyles(),
      positionStyles()
    ),
    styles,
    defaultStyles
  )
}

function getControlsStyles(data) {
  let styles = data.getIn(["styles", "#controls"], Map());
  return styles;
}

function getStyles(data, width, height) {
  // TODO: Investigage why display is "inline-block" by default.
  let defaultStyles = Map({position: "relative", display: "block", width: "100%", height: "100%", overflow: "hidden"});
  let additionalStyles = List(["@preserveRatio", "backgroundColor", "color", "textAlign", "minHeight", "minWidth", "width", "height", "maxWidth", "maxHeight", "display", "position", "top", "bottom", "left", "right", "zIndex"]);
  return applyAbsoluteMeasurements(
    applyStyles(
      groupStyles(
        marginStyles(), 
        paddingStyles(), 
        additionalStyles
      ), 
      data.get("styles"), 
      defaultStyles
    ),
    width,
    height
  )
}

// function secondsToMilliSeconds(str) {
//   if (isNaN(str)) {
//     return parseInt(str, 10) * 1000;
//   } else {
//     // Assume we have milliseconds already.
//     return str;
//   }
// }
