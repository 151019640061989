import React from "react";
import Title from "./modules/title/Title";
import Paragraph from "./modules/paragraph/Paragraph";
import Line from "./modules/line/Line";
import ActionButton from "./modules/action_button/ActionButton";
import FXReveal from "./modules/fx_reveal/FXReveal";
import Intro from "./modules/intro/Intro";
import DynResImage from "./modules/res_image/DynResImage";
import PageLinksContainer from "./modules/page_links/PageLinksContainer";
import ViewVideoButton from "./modules/view_video_button/ViewVideoButton";
import ItemContainer from "./modules/item_container/ItemContainer";
import LayoutContainer from "./modules/layout_container/LayoutContainer";
import ColumnContainer from "./modules/column_container/ColumnContainer";
import SlideContainer from "./modules/slide_container/SlideContainer";
import DynPropertyGalleryBrowser from "./modules/property_gallery_browser/DynPropertyGalleryBrowser";
import DynPropertyThumbnailGallery from "./modules/property_thumbnail_gallery/DynPropertyThumbnailGalleryContainer"; // NOT READY
import {transformData} from "./ModuleDataTransformer";

// import {withRouter} from "react-router";

function withRouter(value) {
  return value;
}

// TODO: Fix the incredible repetition when creating modules.
export function createModuleFromId(moduleId, modules, width, height, pageActions = {}) {
  if (moduleId === "") {
    // Special case when sometimes we don't use any module for doing spacings etc.
    return <div />
  }
  if (!modules.has(moduleId)) {
    console.warn(`Module "${moduleId}" was not found`);
    return null;
  }
  var mod = modules.get(moduleId);
  var moduleName = mod.get("moduleName");
  let data = transformData(mod.get("data"), width, height);
  switch(moduleName) {
    case "page-links":
      return wrapFX(
        moduleId, data, 
        React.createElement(PageLinksContainer, {key: moduleId, data, width, height, pageActions}, null)
      );
    case "intro":
      return wrapFX(
        moduleId, data, 
        React.createElement(Intro, {key: moduleId, moduleId, data, width, height}, null)
      );
    case "paragraph":
      return wrapFX(
        moduleId, data, 
        React.createElement(Paragraph, {key: moduleId, moduleId, data}, null)
      );
    case "title":
      return wrapFX(
        moduleId, data, 
        React.createElement(Title, {key: moduleId, moduleId, data}, null)
      );
    case "action-button":
      return wrapFX(
        moduleId, data, 
        React.createElement(ActionButton, {key: moduleId, data, pageActions}, null)
      );
    case "res-image":
      return wrapFX(
        moduleId, data, 
        React.createElement(withRouter(DynResImage), {key: moduleId, data, pageActions}, null)
      );
    case "property-gallery-browser":
      return wrapFX(
        moduleId, data, 
        React.createElement(DynPropertyGalleryBrowser, {key: moduleId, moduleId, data, width, height, pageActions}, null)
      );
    case "property-thumbnail-gallery":
      return wrapFX(
        moduleId, data, 
        React.createElement(DynPropertyThumbnailGallery, {key: moduleId, moduleId, data, width, height, pageActions}, null)
      );
    case "layout-container":
      return wrapFX(
        moduleId, data, 
        React.createElement(LayoutContainer, {key: moduleId, moduleId, createModuleFromId, data, modules, width, height, pageActions}, null)
      );
    case "column-container":
      return wrapFX(
        moduleId, data, 
        React.createElement(ColumnContainer, {key: moduleId, moduleId, createModuleFromId, data, modules, width, height, pageActions}, null)
      );
    case "slide-container":
      return wrapFX(
        moduleId, data, 
        React.createElement(SlideContainer, {key: moduleId, moduleId, createModuleFromId, data, modules, width, height, pageActions}, null)
      );
    case "item-container":
      return wrapFX(
        moduleId, data,
        React.createElement(ItemContainer, {key: moduleId, moduleId, createModuleFromId, data, modules, width, height, pageActions}, null)
      );
    case "view-video-button":
      return wrapFX(
        moduleId, data, 
        React.createElement(ViewVideoButton, {key: moduleId, data, width, height, pageActions}, null)
      );
    case "line":
      return wrapFX(
        moduleId, data, 
        React.createElement(Line, {key: moduleId, data, width, height, pageActions}, null)
      );
    default:
      console.warn(`No module with the name "${moduleName}"`);
      return null;
  }
}

function wrapFX(moduleId, data, component) {
  if (!data.has("fx")) return component;
  return React.createElement(FXReveal, data.get("fx").set("key", moduleId).toJS(), component);
}